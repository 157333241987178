<template>
  <nav v-if="isInstanceInfoFetched" class="navbar navbar-light bg-white shadow">
    <div class="container py-3">
      <router-link class="navbar-brand" to="/">{{ instanceInfo?.name }}</router-link>
    </div>
  </nav>
  <div class="container h-100">
    <router-view v-if="isInstanceInfoFetched" :instanceInfo="instanceInfo" />
  </div>
  <footer class="py-4 bg-white">
    <ul class="nav justify-content-center">
      <li class="nav-item">
        <router-link to="/privacy" class="nav-link">Privacy Policy</router-link>
      </li>
      <li class="nav-item">
        <router-link to="/imprint" class="nav-link">Imprint</router-link>
      </li>
      <li class="nav-item">
        <router-link to="/terms" class="nav-link">Terms and Conditions</router-link>
      </li>
    </ul>
    <p class="text-center mt-4">
      All Rights Reserved © {{ new Date().getFullYear() }}.
    </p>
  </footer>
  <div v-if="fetchError" style="text-align: center;">
    <h1>Website is under maintenance at the moment</h1>
    <h2>Please refresh page in few minutes</h2>
  </div>
</template>

<script>
import { ref } from 'vue';
import { fetchInstanceInfo } from '@/app/api/instance';

export default {
  inheritAttrs: false,
  setup() {
    const isInstanceInfoFetched = ref(false);
    const fetchError = ref(false);

    const instanceInfo = ref(null);

    fetchInstanceInfo()
      .then(async data => {
        instanceInfo.value = data;
        isInstanceInfoFetched.value = true;
        document.title = instanceInfo.value.name;
      })
      .catch(() => { fetchError.value = true; });

    return { isInstanceInfoFetched, fetchError, instanceInfo };
  },
};
</script>
