import { createRouter, createWebHistory, useRoute } from 'vue-router';
import { routes } from '@/app/router/routes';
import { computed } from 'vue';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
});

export const redirectToRoute = route => {
  router.push(route).catch(() => {
    /* */
  });
};

export const useUrl = () => {
  const route = useRoute();

  const id = computed(() => route.params.id);
  const currentPath = computed(() => route.path);
  const currentRouteName = computed(() => router.currentRoute.value.name);
  const currentRoute = computed(() => router.currentRoute.value);

  return {
    id,
    currentRouteName,
    currentPath,
    currentRoute
  };
};

export const reload = () => {
  redirectToRoute(useUrl().currentPath);
};

export default router;
